import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ServicesComponent from './components/ServicesComponent';
import ContactForm from './components/ContactForm';
import QuoteForm from './components/QuoteForm';
import * as serviceWorker from './serviceWorker';
import ReviewsComponent from './components/Reviews';

ReactDOM.render(
  <React.StrictMode>
    <ServicesComponent />
  </React.StrictMode>,
  document.getElementById('services')
);

ReactDOM.render(
  <React.StrictMode>
    <ContactForm />
  </React.StrictMode>,
  document.getElementById('contact')
);

ReactDOM.render(
  <React.StrictMode>
    <QuoteForm />
  </React.StrictMode>,
  document.getElementById('exampleModal')
);

ReactDOM.render(
  <React.StrictMode>
    <ReviewsComponent />
  </React.StrictMode>,
  document.getElementById('reviews')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
