import React, {useState, useEffect, Fragment} from 'react';
// import logo from './logo.svg';
// import './App.css';


const services = [
    {
        name: 'Themed Dancers',
        description: 'Whether you’re looking for sleek gogo dancers, playful character dancers, or a hot choreographed dance number, our themed dancers are the perfect complement to any music event or wonderful as a standalone showcase for any party.',
        images: [
            '/img/services/Themed Dancers 1-square.jpg',
            '/img/services/Themed Dancers 2-square.jpg',
            '/img/services/Themed Dancers 3-square.jpg'
        ],
        examples: [
            'Gogo dancers (ambient, freestyle dancing)',
            'Character dancers',
            'Choreographed dance number'
        ]
    },
    {
        name: 'Characters',
        description: 'Our interactive characters are sure to delight your guests with their fantastic costuming and storytelling. Fully committed to their characters, you’ll forget you hired actors!',
        images: [
            '/img/services/Characters 1-square.jpg',
            '/img/services/Characters 2-square.jpg',
            '/img/services/Characters 3-square.jpg',
            "img/slider/bg9.jpeg",
            "img/slider/bg10.jpeg"
        ],
        examples: [
            'Roaming character groups',
            'Event greeters',
            'Stilt walkers',
            'Clowns',
            'Living statues',
            'Art installations '
        ]
    },
    {
        name: 'Event Greeters',
        description: 'Showstarters event greeters set the tone for a fun night ahead, letting your guests know right away that your party is a cut above the rest with creativity and flare.',
        images: [
            '/img/services/Event Greeters 1-square.jpg',
            '/img/services/Event Greeters 2-square.jpg',
            '/img/services/Event Greeters 3-square.jpg'
        ],
        examples: [
            'Costumed characters',
            'Stilt walkers',
            'Unique serving experiences'
        ]
    },
    {
        name: 'Stilt Walkers',
        description: 'Elevate your next party, literally, with our most popular walkabout act!',
        images: [
            '/img/services/Stilt Walkers 1-square.jpg',
            '/img/services/Stilt Walkers 2-square.jpg',
            '/img/services/Stilt Walkers 3-square.jpg'
        ],
        examples: [
            'Roaming and interactive',
            'Event greeters',
            'Custom characters',
            'Bartender on stilts',
            'Server on stilts'
        ]
    },
    {
        name: 'LED Acts',
        description: 'Light up the night with our mesmerizing LED hoops, levitation wand, fans, dragon staff, and isis wings.',
        images: [
            '/img/services/LED Acts 1-square.jpg',
            '/img/services/LED Acts 2-square.jpg',
            '/img/services/LED Acts 3-square.jpg'
        ],
        examples: [
            'Ambient',
            'Choreographed'
        ]
    },
    {
        name: 'Fire Shows',
        description: 'Light up the night and turn up the heat with our mesmerizing fire hoops, levitation wand, fans, staves, dragon staff, fire crown, and palm torches.',
        images: [
            '/img/services/Fire Shows 1-square.jpg',
            '/img/services/Fire Shows 2-square.jpg',
            '/img/services/Fire Shows 3-square.jpg'
        ],
        examples: [
            'Ambient',
            'Choreographed'
        ]
    },
    {
        name: 'Aerial Shows',
        description: 'With a variety of apparatuses to choose from such as aerial hoop, fabric/silks, and trapeze, your guests will be amazed by our high flying circus shows.',
        images: [
            '/img/services/Aerial Shows 1-square.jpg',
            '/img/services/Aerial Shows 2-square.jpg',
            '/img/services/Aerial Shows 3-square.jpg'
        ],
        examples: [
            'Ambient',
            'Choreographed',
            'Aerial bartending'
        ]
    },
    {
        name: 'Flow Arts/Prop Manipulation',
        description: 'Choose from hula hoop dance, fan performance, isis wings, multi hoop circus acts, partner acts, juggling, and more!',
        images: [
            '/img/services/Flow artsprop manipulation 1-square.jpg',
            '/img/services/Flow artsprop manipulation 2-square.jpg',
            '/img/services/Flow artsprop manipulation 2-square.jpg'
        ],
        examples: [
            'Day props',
            'LED props',
            'Fire props'
        ]
    },
    {
        name: 'Models',
        description: 'Gorgeous female and/or male models with a professional attitude.',
        images: [
            '/img/services/Models 1-square.jpg',
            '/img/services/Models 2-square.jpg',
            '/img/services/Models 3-square.jpg'
        ],
        examples: [
            'Promotional models',
            'Hype girls',
            'Brand ambassadors'
        ]
    },
    {
        name: 'Stage Acts',
        description: 'As our name states, we’re always down to start the show!',
        images: [
            '/img/services/Stage Acts  1-square.jpg',
            '/img/services/Stage Acts  1-square.jpg',
            '/img/services/Stage Acts  1-square.jpg'
        ],
        examples: [
            'Gogo dancers',
            'Ambient or choreographed LED acts',
            'Choreographed dance number'
        ]
    },
    {
        name: 'Ambient Entertainment',
        description: 'Beautiful background entertainment to create ambience for your event.',
        images: [
            '/img/services/Ambient Entertainment 1-square.jpg',
            '/img/services/Ambient Entertainment 2-square.jpg',
            '/img/services/Ambient Entertainment 3-square.jpg'
        ],
        examples: [
            'Gogo dancers',
            'Ambient LED',
            'Ambient fire',
            'Roaming Characters'
        ]
    },
    {
        name: 'Unique Serving Experiences',
        description: 'Serving food? Book a Showstarter Specialty Server/Bartender for a dining experience your guests won’t soon forget!',
        images: [
            '/img/services/Unique Serving Experiences 1-square.jpg',
            '/img/services/Unique Serving Experiences 2-square.jpg',
            '/img/services/Unique Serving Experiences 3-square.jpg'
        ],
        examples: [
            'Strolling table',
            'Specialty servers',
            'Servers on stilts'
        ]
    },
    {
        name: 'Instructors/Workshops',
        description: 'Looking to hone in on your skills or make a Showstarters class accessible to your students or organization? We work with a number of qualified instructors who are eager to share their passion with you!',
        images: [
            '/img/services/Instructors Workshops-square.jpg',
            '/img/services/Instructors Workshops-square.jpg',
            '/img/services/Instructors Workshops-square.jpg'
        ],
        examples: [
            'Dance',
            'Flow arts',
            'Fire'
        ]
    },
    {
        name: 'Holiday Entertainment',
        description: 'Whether you’re planning a corporate holiday party or a small intimate gathering, you can count on Showstarters to bring that extra holiday cheer!',
        images: [
            '/img/services/Holiday Parties 1-square.jpg',
            '/img/services/Holiday Parties 2-square.jpg',
            '/img/services/Holiday Parties 3-square.jpg'
        ],
        examples: []
    },
    {
        name: 'Halloween Entertainment',
        description: 'Halloween is our favorite time of year! Let Showstarters make your Halloween event extra spooky and fun with our custom costumes, special effects makeup, and wild performances.',
        images: [
            '/img/services/Halloween Events 1-square.jpg',
            '/img/services/Halloween Events 2-square.jpg',
            '/img/services/Halloween Events 3-square.jpg'
        ],
        examples: []
    },
    {
        name: 'Circus Entertainment',
        description: 'Circus acts and characters are our specialty! If you’re planning a circus or carnival themed event, you need Showstarters!',
        images: [
            '/img/services/Circus Carnival Themed 1-square.jpg',
            '/img/services/Circus Carnival Themed 2-square.jpg',
            '/img/services/Circus Carnival Themed 3-square.jpg'
        ],
        examples: []
    },
    {
        name: 'Socially Distant and Virtual Performances',
        description: `What better way to stay entertained while remaining a safe social distance than with our 9ft tall stilt walkers, flaming hot fire shows, or wild acrobatics!? \n
        <p>NOW OFFERING: Circus Telegrams
What better way to stay entertained while remaining a safe social distance than with our 9ft tall stilt walkers, flaming hot fire shows, or wild acrobatics!? </p>
<p>NOW OFFERING: Livestream Performers
Make your virtual event more dynamic by adding our performers to your livestream or green screen! </p>
<p>COMING SOON: Showstarters Storytime
Let one of our Showstarters Storytellers entertain your children from the comfort of your home with one of our virtual character sessions. Details coming soon!</p>
`,
        images: [
            '/img/services/Social Distance 1-square.png',
            '/img/services/Social Distance 2-square.jpg',
            '/img/services/Social Distance 1-square.png'
        ],
        examples: []
    },
]

function ServicesComponent() {
    const [currentService, setCurrentService] = useState(0);

  return (
    <section>
        <div className="container pos-r">
            <div className="vartical-line"></div>
            <div className="horizontal-line"></div>
            <div className="row">
                <div className="col-md-10 col-md-offset-1">
                    <div className="heading-text">
                        <h2>What we offer </h2>
                        <p>Engaging, creative, and quality entertainment.</p>
                        <div className="separator"></div>
                    </div>
                </div>
            </div>


            <div className="m-t-20 m-b-80">
                <div className="row">
                    <div id="service-nav" className="col-lg-3 col-md-3 col-xs-12">
                        <ul className="nav nav-pills nav-stacked">
                        {services.map((service, index) => {
                            return (
                                <li role="presentation" className={index == currentService ? 'active' : ''} key={service.name} onClick={() => {setCurrentService(index)}}><a>{service.name}</a></li>
                            )
                        })}
                        
                        </ul>
                    </div>
                    <div className="col-lg-9 col-md-9 col-xs-12">
                        <>
                            {/* <img className="full-size" src={services[currentService].images[0]} alt={services[currentService].name} />
                            
                            <caption>{services[currentService].description}</caption> */}
                            <Service service={services[currentService]} />
                        </>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

function Service(props) {
    const [activeSlide, setActiveSlide] = useState(0);
    const {name, description, images, examples} = props.service;
    return (
      <>
        <h3 class="heading-lg" style={{marginTop: 0}}>{name}</h3>
        <div class="separator m-t-20 width-px-100"></div>

        <p className="m-t-20" dangerouslySetInnerHTML={{__html: description}}></p>


        {/* <h4 class="clr-primary">Examples</h4> */}
        <ul class="list-group">
            {examples.map((example, index) => {
                return (
                    <li key={index} class="list-group-item" style={{border: 0}}><i class="fa fa-circle clr-primary" ></i> {example}</li>
                )
            })}
        </ul>

        <div id="carousel-photo" data-ride="carousel" class="carousel slide">
                    <ol class="carousel-indicators">
                        {images.map((image, index) => {
                            return (
                                <li data-target="#carousel-photo" key={index} data-slide-to={index} className={activeSlide === index} ></li>
                            )
                        })}
                    </ol>
                    <div class="carousel-inner">
                        {images.map((image, index) => {
                            return (
                                <div style={{width: '100%', backgroundColor: 'black'}}  className={`item ${ activeSlide === index ? 'active' : ''}`} >
<img  style={{height: '400px'}} key={index}src={image} alt={name} />
                                </div>
                            )
                        })}
                    </div>
                </div>
      </>
    )
}


{/* <i className='fa fa-angle-left fa-2x'></i> */}

export default ServicesComponent;
