import React, {useState} from 'react';
import { useToast, immediateToast } from 'izitoast-react'

function QuoteForm() {
    const [emailData, setEmailData] = useState({});
    
    function handleChange(e) {
        let target = e.target;
        let key =  target.id;
        let value = e.target.type === "checkbox" ? target.checked : target.value;

        const formData = emailData;
        console.log({
            ...formData,
            [key]: value
        });

        setEmailData({
            ...formData,
            [key]: value
        })
    }


    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    
    function submitMessage() {
        postData('https://56pr6nx9e0.execute-api.us-east-1.amazonaws.com/quote', emailData);
        immediateToast('info', {
            message: 'Email Sent',
            position: 'topRight'
        })
        setEmailData({});
    };


    return (
        <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Quote Form</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{position: 'absolute',
            top: '0',
            right: '1em'}}>
              <span aria-hidden="true" style={{color: 'red', fontSize: '2em'}}>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <section>
              <div>
                  <div className="row">
                      <form>
                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputName2">Name</label>
                              <input type="text" placeholder="" required id="name" value={emailData.name} onChange={handleChange} className="form-control input-lg"/>
                          </div>
                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputEmail2">Email</label>
                              <input type="email" placeholder="" required  id="email" value={emailData.email} onChange={handleChange} className="form-control input-lg"/>
                          </div>
                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputEmail2">Phone #</label>
                              <input type="phone" placeholder="xxx-xxx-xxxx" required  id="phone" value={emailData.phone} onChange={handleChange} className="form-control input-lg"/>
                          </div>
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputMessage2">What is your budget for entertainment?</label>
                              <select className="form-control input-lg"  id="budget" value={emailData.budget} onChange={handleChange}>
                              <option value="">Select Choice</option>
                                  <option value="0">What is your budget for entertainment</option>
                                  <option value="Less than $300">Less than $300</option>
                                  <option value="$300 - $500">$300 - $500</option>
                                  <option value="$500 - $1,000">$500 - $1,000</option>
                                  <option value="$1,000 - $3,000">$1,000 - $3,000</option>
                                  <option value="$3,000 - $5,000">$3,000 - $5,000</option>
                                  <option value="$5,000 - $10,000">$5,000 - $10,000</option>
                                  <option value="$10,000 - $20,000">$10,000 - $20,000</option>
                                  <option value="$20,000 +">$20,000 +</option>
                              </select>
                          </div>
  
                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputEmail2">Address / Venue Location</label>
                              <input type="text" placeholder="" required  id="address" value={emailData.address} onChange={handleChange} className="form-control input-lg"/>
                          </div>

                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputEmail2">City</label>
                              <input type="text" placeholder="" required  id="city" value={emailData.city} onChange={handleChange} className="form-control input-lg"/>
                          </div>

                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputEmail2">State</label>
                              <input type="text" placeholder="" required  id="state" value={emailData.state} onChange={handleChange} className="form-control input-lg"/>
                          </div>

                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputEmail2">Country</label>
                              <input type="text" placeholder="" required  id="country" value={emailData.country} onChange={handleChange} className="form-control input-lg"/>
                          </div>
  
                          {/* <!-- date pciekr --> */}
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputMessage2">Is this a private or public event?</label>
                              <select className="form-control input-lg" id="isEventPublic" value={emailData.isEventPublic} onChange={handleChange}>
                                 <option value="">Select Choice</option>
                                  <option value="private">Private</option>
                                  <option value="public">Public</option>
                              </select>
                          </div>

                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputEmail2">Date of event?</label>
                              <input type="text" placeholder="" required  id="eventDate" value={emailData.eventDate} onChange={handleChange} className="form-control input-lg"/>
                          </div>
  
                          <div className="form-group col-md-6">
                              <label htmlFor="exampleInputEmail2">What time does your event run?</label>
                              <input type="text" placeholder="" required  id="eventTime" value={emailData.eventTime} onChange={handleChange} className="form-control input-lg"/>
                          </div>
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputSubject2">How long are you looking for entertainers to be out?</label>
                              <input type="text" placeholder="Number of hours" id="eventDuration" value={emailData.eventDuration} onChange={handleChange} className="form-control input-lg"/>
                          </div>
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputMessage2">is it indoor or outdoor event?</label>
                              <select className="form-control input-lg" id="isEventIndoor" value={emailData.isEventIndoor} onChange={handleChange}>
                                  <option value="">Select Choice</option>
                                  <option value="indoor">Indoor</option>
                                  <option value="outdoor">Outdoor</option>
                              </select>
                          </div>
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputSubject2">How many guests are you expecting?</label>
                              <input type="number" placeholder="" id="numOfGuests" value={emailData.numOfGuests} onChange={handleChange} className="form-control input-lg"/>
                          </div>
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputMessage2">What age group are the guests?</label>
                              <select className="form-control input-lg" id="ageGroup" value={emailData.ageGroup} onChange={handleChange}>
                              <option value="">Select Choice</option>
                                  <option value="kidsOnly">Kids Only</option>
                                  <option value="allAges">All Ages</option>
                                  <option value="adultOnly">Adult Only</option>
                              </select>
                          </div>
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputMessage2">Does your event have a specific theme? If yes, what is it?</label>
                              <textarea placeholder="Cartoons, blue and yellow, etc.." rows="3" required id="eventTheme" value={emailData.eventTheme} onChange={handleChange} className="form-control input-lg"></textarea>
                          </div>
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputMessage2">Have you or are you planning on hiring entertainment outside of Showstarters? (i.e. musician/band, live art, dancers, etc.)</label>
                              <textarea placeholder="If yes, what else?" rows="3" required id="hiringOtherEntertainment" value={emailData.hiringOtherEntertainment} onChange={handleChange} className="form-control input-lg"></textarea>
                          </div>
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputMessage2">Which acts or services appeal to you most? Select one or all that apply:</label>
  
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="themeDancers" checked={emailData.themeDancers} onChange={handleChange} aria-label="..."/> Themed Dancers
                              </label>
                              </div>
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="characters" checked={emailData.characters} onChange={handleChange} aria-label="..."/> Characters
                              </label>
                              </div>
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="eventGreeters" checked={emailData.eventGreeters} onChange={handleChange} aria-label="..."/> Event greeters
                              </label>
                              </div>
  
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="stiltWalkers" checked={emailData.stiltWalkers} onChange={handleChange} aria-label="..."/> Stilt walkers
                              </label>
                              </div>
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="ledActs" checked={emailData.ledActs} onChange={handleChange} aria-label="..."/> LED acts
                              </label>
                              </div>
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="fireShows" checked={emailData.fireShows} onChange={handleChange} aria-label="..."/> Fire shows
                              </label>
                              </div>
                              
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="aerialShows" checked={emailData.aerialShows} onChange={handleChange} aria-label="..."/> Aerial shows
                              </label>
                              </div>
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="flowArts" checked={emailData.flowArts} onChange={handleChange} aria-label="..."/> Flow arts/prop manipulation
                              </label>
                              </div>
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="promoModels" checked={emailData.promoModels} onChange={handleChange} aria-label="..."/> Promo models
                              </label>
                              </div>
  
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="stageActs" checked={emailData.stageActs} onChange={handleChange} aria-label="..."/> Stage acts
                              </label>
                              </div>
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="ambientEntertainment" checked={emailData.ambientEntertainment} onChange={handleChange} aria-label="..."/> Ambient entertainment
                              </label>
                              </div>
                              <div className="checkbox">
                              <label>
                                  <input type="checkbox" id="uniqueServingExperiences" checked={emailData.uniqueServingExperiences} onChange={handleChange} aria-label="..."/> Unique serving experiences
                              </label>
                              </div>
  
                                <div className="checkbox">
                                <label>
                                    <input type="checkbox" id="choreography" checked={emailData.choreography} onChange={handleChange} aria-label="..."/> Choreography
                                </label>
                                </div>
                                <div className="checkbox">
                                <label>
                                    <input type="checkbox" id="instructors" checked={emailData.instructors} onChange={handleChange} aria-label="..."/> Instructors/workshops
                                </label>
                                </div>
                                <div className="checkbox">
                                <label>
                                    <input type="checkbox" id="custom" checked={emailData.custom} onChange={handleChange} aria-label="..."/> I have a custom idea not listed here
                                </label>
                                </div>
                          </div>
  
                          <div className="form-group col-md-12">
                              <label htmlFor="exampleInputMessage2">How did you hear about us?</label>
                              <textarea placeholder="Facebook, Instagram, word of mouth" rows="3" required id="howDidYouHearOfUs" value={emailData.howDidYouHearOfUs} onChange={handleChange} className="form-control input-lg"></textarea>
                              <button className="btn btn-c-primary btn-block inverse btn-lg m-t-20" onClick={submitMessage}>Request Quote</button>
                          </div>
                      </form>
                      {/* <!--== contact us form ends ===--> */}
                  </div>
              </div>
          </section>
          {/* <!--=== Contact Us Section Ends ===--> */}
          </div>
        </div>
      </div>
    )
};

export default QuoteForm;