import React, { useState } from 'react';

const reviews = [{
    id: 0,
    title: "The Showstarters perform with grace and elegance, transporting the crowd into their world of fantasy, amusement and wonder.",
    person: "Marc Vitagliano",
    company: "Entertainment Manager at Buffalo RiverWorks"
},
{
    id: 1,
    title: "From start to finish, Showstarters Entertainment was extremely easy to work with.They saw our vision for our event and helped make it come to life.Their performers added an extra flair and ‘wow factor’ to our Summer Soiree event and all of our guests couldn't stop taking photos with them! Definitely worth it!",
    person: "Laurie Clark",
    company: "Owner at Avanti Mansion"
},
{
    id: 2,
    title: "Showstarters Entertainment = Awesome! I’ve used them for two corporate events and they worked with me on overall party ideas and character development. They dove in head first for my ‘Beauty and the Beast’ party and made it come to life! Their commitment to costuming and being in character is amazing. Everyone on their staff is professional and friendly.",
    person: "Pauline Johnson",
    company: "Marketing Director at Premium Mortgage"
},
{
    id: 3,
    title: "As an event promoter in Buffalo - when we need gogo dancers, characters, stilt walkers or the like - we call Showstarters Entertainment. Rock solid group that will customarily work with you.",
    person: "Mike Marshall",
    company: "Owner/Talent Buyer at MNM Presents"
},
{
    id: 4,
    title: "UE loves adding unique entertainment to our events, and our first call is Showstarters. Their professionalism and ability to work a room is top notch! Small intimate gathering, corporate parties.. up to festivals and large shows, definitely LOVE working beside them to execute a flawless event.",
    person: "Rebecca Dodge",
    company: "Owner & Event Planner at Untamed Events"
},
{
    id: 5,
    title: "Had the privilege of working with these talented performers. Their commitment is a beautiful thing to see. And the costumes and performances are top-notch!",
    person: "Debi Mansour",
    company: "MC Crackerjack"
},
{
    id: 6,
    title: "This is an amazing group of artists who never fail to mesmerize, inspire, and overwhelm its onlookers with joy. Perfectly curated. Highly recommend.",
    person: "Nick Kin",
    company: ""
},
{
    id: 7,
    title: "Showstarters Entertainment is amazing! So creative, professional, and the life of the party. Would 100% recommend them for any event.",
    person: "Jenna Cohen",
    company: ""
},
{
    id: 8,
    title: "Absolutely incredible performance!",
    person: "Andrew Slattery",
    company: ""
}]

const clientNames = [
    {
        id: 0,
        clientList: [
            '98.5 KRZ',
            '103.3 The Edge',
            'After Dark Presents',
            'Avanti Mansion',
            'Blue Moon Bash',
            'Buffalo Rising',
            'Buffalo Riverworks',
            'Elements Music and Arts Festival'
        ]
    },
    {
        id: 1,
        clientList: [
            'Doom ‘N Boom',
            'CanAm Parade',
            'Curtain UP! Buffalo',
            'Dragonfly Tavern',
            'The New Echo Lake Resort',
            'Fanboy Promotions',
            'Hyperglow Tour',
            'Lux Bar/Lux Birthday Party',
        ]
    },
    {
        id: 2,
        clientList: [
            'Premium Mortgage',
            'Samuel’s Grande Manor',
            'Statler City',
            'Strong Museum of Play Rochester',
            'Tailor Made Festival',
            'The Terrace at Delaware Park',
            'Town Ballroom',
            'University at Buffalo',
        ]
    },
    {
        id: 3,
        clientList: [
            'Magnolia Events',
            'Madd Tattoo/Madd Tiki Luau',
            'MNM Presents',
            'Montage Mountain',
            'Music is Art',
            'Night Nation Run',
            'Park Ave Fest',
            'Photo City Improv',
        ]
    },
    {
        id: 4,
        clientList: [
            'Untamed Events',
            'VENU',
            'Witches Ball',
            'Woodlawn Beach & Tiki Bar',
            'Electric Forest',
            'Local Matters Inc',
            'Lost Lands',
            'Nova Han Productions'
        ]
    }
]

let textStyles = {
    float: 'left',
    margin: '2em'
}

function ReviewsComponent() {
    const [activeSlide, setActiveSlide] = useState(0)
    return (
        <>
        <div id="slider1" style={{backgroundColor: '#a349ff'}} class="bg-cover height-100">
        <div class="bg-alpha-black-8 height-100">
            <div class="container height-100">
                <div class="table">
                    <div class="table-middle-row">
                        <div class="row m-v-80">
                            <div class="col-md-12 clr-white text-center">
                                <h3 class="text-uppercase font-weight-600">What our clients say </h3>
                                <div class="separator width-20 center-block m-t-30"></div>
                                <div class="col-md-12">
                                    {/* <!-- Bootstrap Carousel Text Slider Start ===--> */}
                                    <div id="carousel-id" data-ride="carousel" class="carousel slide" data-interval="8000">
                                        <ol class="carousel-indicators">
                                            {reviews.map(review => {
                                                return (
                                                    <li data-target="#carousel-id" key={review.id} data-slide-to={review.id} className={activeSlide === review.id}></li>
                                                )
                                            })}
                                        </ol>
                                        <div class="carousel-inner">
                                            {reviews.map(review => {
                                                return (
                                                    <div key={review.id} className={`item ${ activeSlide === review.id ? 'active' : ''}`}>
                                                    <p class="m-t-40 m-b-20 font-size-17 font-weight-500">
                                                        <i>"{review.title}"</i>
                                                    </p>
                                                    <p class="text-uppercase font-size-14 font-weight-600 m-t-10 m-b-reset">- {review.person}</p>
                                                    <p class="font-size-12 font-weight-600 m-b-60 m-t-reset">{review.company}</p>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    {/* <!-- Bootstrap Carousel Text Slider End ===--> */}
                                </div>
                            </div>

                            {/* <div class="col-md-6 clr-white text-center">
                                <h3 class="text-uppercase font-weight-600">Companies we worked with? </h3>
                                <p class="font-size-18 m-t-20 font-weight-300">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                                <div class="separator width-20 center-block m-t-30"></div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="slider2" class="bg-cover height-100 bg-alpha-black-6">
        <div class="bg-alpha-black-8 height-100">
            <div class="container height-100">
                <div class="table">
                    <div class="table-middle-row">
                        <div class="row m-v-80">
                            <div class="col-md-12 clr-white text-center">
                                <h3 class="text-uppercase font-weight-600">List of Clients </h3>
                                <div class="separator width-20 center-block m-t-30"></div>
                                <div class="col-md-12">
                                    {/* <!-- Bootstrap Carousel Text Slider Start ===--> */}
                                    <div id="carousel-id2" data-ride="carousel" class="carousel slide" data-interval="8000">
                                        <ol class="carousel-indicators">
                                            {clientNames.map(client => {
                                                return (
                                                    <li data-target="#carousel-id2" key={client.id} data-slide-to={client.id} className={activeSlide === client.id}></li>
                                                )
                                            })}
                                        </ol>
                                        <div class="carousel-inner">
                                            {clientNames.map(client => {

                                                
                                                return (
                                                <div key={client.id} className={`item ${ activeSlide === client.id ? 'active' : ''}`} style={{height: '120px'}}>
                                                    <p class="text-uppercase font-size-16 font-weight-600 m-t-10 m-b-reset clr-primary" style={textStyles}>{client.clientList[0]}</p>
                                                    <p class="text-uppercase font-size-16 font-weight-600 m-t-10 m-b-reset clr-primary" style={textStyles}>{client.clientList[1]}</p>
                                                    <p class="text-uppercase font-size-16 font-weight-600 m-t-10 m-b-reset clr-primary" style={textStyles}>{client.clientList[2]}</p>
                                                    <p class="text-uppercase font-size-16 font-weight-600 m-t-10 m-b-reset clr-primary" style={textStyles}>{client.clientList[3]}</p>
                                                    <p class="text-uppercase font-size-16 font-weight-600 m-t-10 m-b-reset clr-primary" style={textStyles}>{client.clientList[4]}</p>
                                                    <p class="text-uppercase font-size-16 font-weight-600 m-t-10 m-b-reset clr-primary" style={textStyles}>{client.clientList[5]}</p>
                                                    <p class="text-uppercase font-size-16 font-weight-600 m-t-10 m-b-reset clr-primary" style={textStyles}>{client.clientList[6]}</p>
                                                    <p class="text-uppercase font-size-16 font-weight-600 m-t-10 m-b-reset clr-primary" style={textStyles}>{client.clientList[7]}</p>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    {/* <!-- Bootstrap Carousel Text Slider End ===--> */}
                                </div>
                            </div>

                            {/* <div class="col-md-6 clr-white text-center">
                                <h3 class="text-uppercase font-weight-600">Companies we worked with? </h3>
                                <p class="font-size-18 m-t-20 font-weight-300">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                                <div class="separator width-20 center-block m-t-30"></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
    )
};

export default ReviewsComponent;