import React, {useState} from 'react';
import { useToast, immediateToast } from 'izitoast-react';

function ContactForm() {
    const [emailData, setEmailData] = useState({});
    
    function handleChange(e) {
        let target = e.target;
        let key =  target.id;
        let value = target.value;

        const formData = emailData;

        setEmailData({
            ...formData,
            [key]: value
        })
    }


    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        console.log(data);
        return response.json(); // parses JSON response into native JavaScript objects
    }

    
    function submitMessage(e) {
        e.preventDefault();
        let url = `https://56pr6nx9e0.execute-api.us-east-1.amazonaws.com/contact`;
        url = "/contact";
        postData(url, emailData);
        immediateToast('info', {
            message: 'Email Sent',
            position: 'topRight'
        })
        setEmailData({});
    };


    return (
        <div className="container pos-r">
            {/* <div className="vartical-line"></div> */}
            {/* <div className="horizontal-line"></div> */}
            <div className="row">
                <div className="col-md-10 col-md-offset-1">
                    <div className="heading-text" style={{marginTop: "2em"}}>
                        <h2>get in touch with us</h2>
                        <p>Feel free to reach out on social media</p>
                        {/* <p>Still have Questions? Contact Us using the Form below.</p> */}
                        <address>
                            <span>
                                <a href="https://www.facebook.com/showstarters.entertainment/" className="decoration-none" target="_blank">
                                    <span className="icon icon-fb icon-2x bdr-rad-circle icon-nexus">
                                        <i className="fa fa-facebook"></i>
                                    </span>
                                </a>
                            </span>
                            <span>
                                <a href="https://www.instagram.com/showstarters_entertainment/" className="decoration-none" target="_blank">
                                    <span className="icon icon-twitter icon-2x bdr-rad-circle icon-nexus">
                                        <i className="fa fa-instagram"></i>
                                    </span>
                                </a>
                            </span>
                        </address>
                        {/* <div className="separator"></div> */}
                    </div>
                </div>
            </div>
            {/* <div className="row m-t-50 m-b-80">
                <div className="col-md-10 col-md-offset-1">
                <div className="row m-v-80">
                    <div className="col-md-8">
                        <div className="row">
                            <form>
                                <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputName2" className="sr-only">Name</label>
                                    <input type="text" placeholder="Name" required id="name" value={emailData.name} className="form-control input-lg" onChange={handleChange}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputEmail2" className="sr-only">Email</label>
                                    <input type="email" placeholder="Email" required id="email" value={emailData.email} className="form-control input-lg" onChange={handleChange}/>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="exampleInputSubject2" className="sr-only">Subject</label>
                                    <input type="text" placeholder="Subject" id="subject" value={emailData.subject} className="form-control input-lg" onChange={handleChange}/>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="exampleInputMessage2" className="sr-only">Message</label>
                                    <textarea placeholder="Message" rows="3" required id="message" value={emailData.message} className="form-control input-lg" onChange={handleChange}></textarea>
                                    <button className="btn btn-c-primary inverse btn-lg m-t-20" onClick={submitMessage}>Submit Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="clr-dark-xl heading-text">
                            <h3 className="font-wight-600 font-size-24"> Want a faster response?</h3>
                            <p>
                                Feel free to reach out on social media
                            </p>
                        </div>
                        <address>
                            <span>
                                <a href="https://www.facebook.com/showstarters.entertainment/" className="decoration-none" target="_blank">
                                    <span className="icon icon-fb icon-2x bdr-rad-circle icon-nexus">
                                        <i className="fa fa-facebook"></i>
                                    </span>
                                </a>
                            </span>
                            <span>
                                <a href="https://www.instagram.com/showstarters_entertainment/" className="decoration-none" target="_blank">
                                    <span className="icon icon-twitter icon-2x bdr-rad-circle icon-nexus">
                                        <i className="fa fa-instagram"></i>
                                    </span>
                                </a>
                            </span>
                        </address>
                    </div>
                </div>
                </div>
            </div> */}
    </div>
    )
};

export default ContactForm;